
import { httpPost } from '@/api';
import useCountryPlant from '@/hooks/useCountryPlant';
import { ajaxLoading, errorMessage, successMessage, setTitleLabel } from '@/utils/publicMethods';

import { defineComponent, PropType } from 'vue';
import useFormData from './useData';
export default defineComponent({
  components: {},
  props: {
    /** 表单新增编辑状态 1新增2编辑 3删除 */
    formType: {
      type: Number as PropType<number>,
      default: 1,
    },
    /** 客户id  */
    eventId: {
      type: String as PropType<string>,
      default: '',
    },
    /** 事件状态 1.客户 2.商机 */
    eventType: {
      type: Number as PropType<number>,
    },
    data: {
      type: Object as PropType<any>,
    },
  },
  emits: ['close'],
  setup(props, ctx) {
    const { setCountryList, countryList, portList, setPortList } = useCountryPlant();
    const { rules, formData, formRef, setFormData } = useFormData();
    const close = (status = false) => {
      ctx.emit('close', status);
    };
    /** 提交表单 校验重复 */
    const saveFormData = async () => {
      formRef.value.validate(async (valid: any) => {
        if (valid) {
          ajaxLoading.lock();
          const res = await httpPost('/malicrm/declareCondition/operationDeclareCondition', formData);
          ajaxLoading.unLock();
          if (res.code === 200) {
            if (props.formType === 1) {
              successMessage('保存成功', 3000);
            } else {
              successMessage('编辑成功', 3000);
            }
            close(true);
          } else {
            errorMessage(res.message);
          }
        }
      });
    };
    // 初始化口岸国家下拉选项
    setPortList();
    setCountryList('');
    if (props.formType > 1) {
      setFormData(props.data);
    } else {
      setFormData({ fundingId: props.eventId });
    }
    return {
      close,
      formData,
      saveFormData,
      rules,
      formRef,
      setTitleLabel,
      countryList,
      portList,
    };
  },
});
