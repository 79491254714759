import { reactive, ref } from 'vue';

export default () => {
  const formRef = ref(); // 表单校验对象使用
  type FormData = {
    attachmentDtos: any[] | null;
  };
  const formData: FormData = reactive({
    attachmentDtos: null,
  });
  const rules = reactive({
    attachmentDtos: [
      {
        required: true,
        message: '请上传附件',
        trigger: 'change',
      },
    ],
  });

  return { rules, formData, formRef };
};
