import { reactive, ref, toRefs } from 'vue';
type FormData = {
  consigneeName: any[];
  fundingBusinessType: any[];
  fundingId: number | string;
  fundingShortName: string;
  id: number | string;
  portName: any[];
  totalAmount: number | string;
  version: number | string;
  [propName: string]: any;
};
export default (params) => {
  const formRef = ref(); // 表单校验对象使用
  const formData: FormData = reactive({
    consigneeName: [],
    fundingBusinessType: [],
    fundingId: null,
    fundingShortName: null,
    id: null,
    portName: [],
    totalAmount: null,
    version: null,
    ...params,
  });
  const customFormData = reactive({
    consigneeTypeList: [], // 抬头下拉选项
    portNameList: [], // 口岸下拉选项
    disabledConsigneeName: [], // 存放禁止删除的选项
    disabledPortName: [], // 存放禁止删除的口岸信息
    disabledFundingBusinessType: [],
    Options: {
      // 业务类型
      busTypeOpt: [
        { label: '期货', value: 1 },
        { label: '现货', value: 2 },
      ],
    },
  });
  const rules = reactive({
    consigneeName: [
      { required: true, message: '请选择抬头', trigger: 'change' },
    ], // 抬头
    totalAmount: [
      { required: true, message: '请输入资金额度(元)', trigger: 'blur' },
    ], // 资金额度
    portName: [{ required: true, message: '请选择口岸', trigger: 'change' }], // 口岸
    fundingBusinessType: [
      { required: true, message: '请选择业务类型', trigger: 'change' },
    ],
  });
  const setFormData = (paramsData: any) => {
    for (const key in formData) {
      if (Object.prototype.hasOwnProperty.call(paramsData, key)) {
        formData[key] = paramsData[key];
      }
    }
  };
  const valInteger = (e, prop) => {
    let value = e.target.value;
    value = value.replace(/^(0+)|[^\d]+/g, '');
    if (value.length > 0) {
      if (!value) {
        formData[prop] = null;
      } else {
        formData[prop] = value;
      }
    } else {
      formData[prop] = null;
    }
  };
  return {
    rules,
    formData,
    formRef,
    setFormData,
    ...toRefs(customFormData),
    valInteger,
  };
};
