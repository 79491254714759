import { reactive, ref } from 'vue';

export default (params) => {
  const formRef = ref(); // 表单校验对象使用
  type CommuniData = {
    email: string; // 电子邮箱
    fundingId: string; // 资金方ID
    id: string | null; // 资金方联系人id
    name: string; // 姓名
    position: string | null; // 职位
    remark: string | null; // 备注
    sex: number; // 性别(参照系统常量contacts_sex)
    telephone: string | number; // 电话
    version: null | number; // 版本号
    weixin: string; // 微信号
    [propName: string]: any;
  };
  const formData: CommuniData = reactive({
    email: null, // 电子邮箱
    fundingId: null, // 资金方ID
    id: null, // 资金方联系人id
    name: null, // 姓名
    position: null, // 职位
    remark: null, // 备注
    sex: 1, // 性别(参照系统常量contacts_sex)
    telephone: null, // 电话
    version: null, // 版本号
    weixin: null, // 微信号
    ...params,
  });

  const rules = reactive({
    name: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
    telephone: [{ required: true, message: '请输入联系电话', trigger: 'blur' }],
  });
  const valChinese = (e, prop) => {
    let value = e.target.value;
    value = value.replace(/[^\u4E00-\u9FA5]/g, '');
    if (value) {
      formData[prop] = value;
    } else {
      formData[prop] = null;
    }
  };
  const valPhone = (e, prop) => {
    let value = e.target.value;
    value = value.replace(/[^\d|^-]+/g, '');
    if (value) {
      formData[prop] = value;
    } else {
      formData[prop] = null;
    }
  };
  return { rules, formData, formRef, valChinese, valPhone };
};
