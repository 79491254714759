import { ref } from 'vue';

export default function usePagination() {
  const tableData = ref();
  const paginationRef = ref();
  const refashTable = () => {
    paginationRef.value.refashTable();
  };
  const empty = (val) => {
    if (val !== null && val !== '') {
      return val;
    } else {
      return '-';
    }
  };
  return {
    tableData,
    paginationRef,
    refashTable,
    empty,
  };
}
