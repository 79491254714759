
import { httpPost } from '@/api';
import FileContent from '@/components/FileContent/FileContent.vue';
import {
  ajaxLoading,
  errorMessage,
  successMessage,
  setTitleLabel,
} from '@/utils/publicMethods';

import { defineComponent, PropType } from 'vue';
import useFormData from './useData';
export default defineComponent({
  components: { FileContent },
  props: {
    /** 表单新增编辑状态 1新增2编辑 3删除 */
    formType: {
      type: Number as PropType<number>,
    },
    /** 事件id  */
    eventId: {
      type: [Number, String],
    },
    /** 事件状态 1.线索 2.客户 3.商机 */
    eventType: {
      type: Number as PropType<number>,
    },
    /** 事件状态 1.线索 2.客户 3.商机 */
    data: {
      type: Object as PropType<any>,
    },
    scoreName: {
      type: String as PropType<string>,
    },
  },
  emits: ['close'],
  setup(props, ctx) {
    const { rules, formData, formRef } = useFormData();
    /** 获取文件信息 */
    const getFileUrls = (fileList: any[]) => {
      if (fileList.length > 0) {
        formData.fundingBaseFileName = fileList[0].name;
        formData.fundingBaseFileUrl = fileList[0].url;
      } else {
        formData.fundingBaseFileName = null;
        formData.fundingBaseFileUrl = null;
      }
      formRef.value.validate();
    };
    const close = (status = false) => {
      ctx.emit('close', status);
    };
    /** 提交表单 */
    const saveFormData = async () => {
      if (formData.fundingBaseFileUrl) {
        formData.fundingId = props.eventId;
        ajaxLoading.lock();
        const res = await httpPost(
          '/malicrm/funding/saveFundingMemoAttachment',
          formData
        );
        ajaxLoading.unLock();
        if (res.code === 200) {
          if (props.formType === 1) {
            successMessage('添加成功', 2000);
          } else {
            successMessage('编辑成功', 2000);
          }
          close(true);
        } else {
          errorMessage(res.message);
        }
      } else {
        errorMessage('请上传资金使用说明书文件！');
      }
    };
    return {
      close,
      formData,
      saveFormData,
      rules,
      formRef,
      setTitleLabel,
      getFileUrls,
    };
  },
});
