
import { httpGet, httpPost } from '@/api';
import { computed, reactive, ref, toRefs } from 'vue';
import { erpConfirm, errorMessage, getRouterParams, successMessage } from '@/utils/publicMethods';
import router from '@/router';
import BaseInfo from './components/BaseInfo/BaseInfo.vue';
import ViewRise from './components/ViewRise/index.vue';
import useVisible from '@/hooks/useVisible';
import FundingForm from './components/FundingForm/FundingForm.vue';
import ViewContract from './components/ViewContract/index.vue';
import ViewStatement from './components/ViewStatement/index.vue';
import ViewBank from './components/ViewBank/index.vue';
import ViewContact from './components/ViewContact/index.vue';
import ViewAttachment from './components/Attachment/index.vue';
import ViewDeclare from './components/ViewDeclare/index.vue';
export default {
  name: 'FundingDetail',
  components: {
    BaseInfo,
    FundingForm,
    ViewRise,
    ViewContract,
    ViewStatement,
    ViewBank,
    ViewContact,
    ViewAttachment,
    ViewDeclare,
  },
  props: {},
  setup() {
    // 获取路由参数
    const { id, type: paramType, backFrom } = getRouterParams('FundingDetail');
    // 当前列表状态
    const type = ref(paramType as number);
    const detailsData = ref();
    const load = ref(true);
    const chooseRef = ref();
    /** 编辑线索展示 */
    const { visible, showVisible } = useVisible();

    const leftTitleNames = reactive({
      viewRiseTitle: '抬头',
      viewDeclareTitle: '报单条件',
      viewContractTitle: '框架合同',
      viewStatementTitle: '资金说明书',
      viewBankTitle: '银行信息',
      viewContactTitle: '联系人',
      viewAttachmentTitle: '附件',
    });
    // 客户框架合同大于1才显示
    const frameWorkShow = ref(false);
    // 是否能编辑
    const isHidden = computed(() => {
      return type.value > 1;
    });
    const scoreName = computed(() => {
      return detailsData.value?.customerName;
    });

    // 初始化数据
    const initData = async () => {
      load.value = false;
      const res = await httpGet(`/malicrm/funding/getFundingDetailById/${id}`);
      if (res.code === 200) {
        detailsData.value = res.result;
        load.value = true;
      } else {
        // 这里传个12什么意思
        if (res.code === 12) {
          // todo 这里也不知道这里作什么的，到时候调查下
        } else {
          errorMessage(res.message);
        }
      }
    };
    const closeVisible = (status) => {
      visible.value = false;
      if (status) {
        initData();
      }
    };
    // 设置标题方法
    const getViewRiseTitle = (str) => {
      leftTitleNames.viewRiseTitle = str;
    };
    const getViewContractTitle = (str) => {
      leftTitleNames.viewContractTitle = str;
    };
    const getViewStatementTitle = (str) => {
      leftTitleNames.viewStatementTitle = str;
    };
    const getViewBankTitle = (str) => {
      leftTitleNames.viewBankTitle = str;
    };
    const getViewContactTitle = (str) => {
      leftTitleNames.viewContactTitle = str;
    };
    const getViewAttachmentTitle = (str) => {
      leftTitleNames.viewAttachmentTitle = str;
    };
    // 报单条数
    const getViewDeclareTitle = (str) => {
      leftTitleNames.viewDeclareTitle = str;
    };

    // 删除
    const deleteLeads = () => {
      erpConfirm('确定要删除当前资金方吗？').then(async () => {
        const params = {
          id: detailsData.value.id,
          version: detailsData.value.version,
        };
        const res = await httpPost('/malicrm/funding/deleteFunding', params);
        if (res.code === 200) {
          successMessage('已删除');
          router.push('/basicInformation/funding');
        } else {
          errorMessage(res.message);
        }
      });
    };
    // 还原
    const reductionLeads = () => {
      erpConfirm('确定要还原当前客户吗？').then(async () => {
        const params = {
          id: detailsData.value.id,
          version: detailsData.value.version,
          operateType: 2,
        };
        const res = await httpPost('/malicrm/customer/deReCustomer', params);
        if (res.code === 200) {
          successMessage('已还原');
          type.value = 1;
          initData();
        } else {
          errorMessage(res.message);
        }
      });
    };
    // 返回操作
    const back = () => {
      if (backFrom) {
        router.push(backFrom);
      }
    };
    initData();
    return {
      load,
      id,
      type,
      isHidden,
      detailsData,
      back,
      ...toRefs(leftTitleNames),
      frameWorkShow,
      getViewRiseTitle,
      getViewDeclareTitle,
      deleteLeads,

      chooseRef,
      reductionLeads,
      scoreName,
      visible,
      showVisible,
      closeVisible,
      getViewContractTitle,
      getViewStatementTitle,
      getViewBankTitle,
      getViewContactTitle,
      getViewAttachmentTitle,
    };
  },
};
