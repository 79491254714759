
import { httpPost } from '@/api';
import { ajaxLoading, errorMessage, successMessage, setTitleLabel } from '@/utils/publicMethods';

import { defineComponent, PropType } from 'vue';
import useFormData from './useData';
export default defineComponent({
  components: {},
  props: {
    /** 表单新增编辑状态 1新增2编辑 3删除 */
    formType: {
      type: Number as PropType<number>,
    },
    /** 资金方id  */
    eventId: {
      type: Number as PropType<number>,
    },
    /** 事件状态 1.客户 2.商机 */
    eventType: {
      type: Number as PropType<number>,
    },
    data: {
      type: Object as PropType<any>,
    },
    /** 客户或商机信息 */
    eventData: {
      type: Object as PropType<any>,
    },
    fullName: {
      // 资金方全称
      type: [String, Number],
      default: '',
    },
  },
  emits: ['close'],
  setup(props, ctx) {
    let paramData = {
      operateType: props.formType,
      fundingId: props.eventId,
      source: props.eventType,
      fullName: props.fullName,
    };
    if ((props.formType as number) > 1) {
      paramData = Object.assign(paramData, props.data);
    }
    const { rules, formData, formRef, valChinese, valPhone } = useFormData(paramData);
    const close = (status = false) => {
      ctx.emit('close', status);
    };
    /** 提交表单 校验重复 */
    const saveFormData = async () => {
      formRef.value.validate(async (valid: any) => {
        if (valid) {
          const params = {
            ...formData,
          };
          ajaxLoading.lock();
          const res = await httpPost('/malicrm/funding/saveFundingContacts', params);
          ajaxLoading.unLock();
          if (res.code === 200) {
            if (props.formType === 1) {
              successMessage('保存成功', 3000);
            } else {
              successMessage('编辑成功', 3000);
            }
            close(true);
          } else {
            errorMessage(res.message);
          }
        }
      });
    };
    const changeInput = (data: any, name: string, value: string) => {
      value = value.replace(/[^-\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace(/-{2,}/g, ''); // 只保留第一个. 清除多余的
      value = value.replace('-', '$#$').replace(/-/g, '').replace('$#$', '-');
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
      data[name] = value;
    };
    return {
      close,
      formData,
      saveFormData,
      rules,
      formRef,
      setTitleLabel,
      changeInput,
      valChinese,
      valPhone,
    };
  },
});
