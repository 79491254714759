import { reactive, ref } from 'vue';
type SetNull<T> = {
  // 遍历T类型 给每个P属性新增null类型
  [P in keyof T]: T[P] | null | undefined;
};

export type FormData = SetNull<{
  // 目的港
  fundingId: string;
  arrivalPorts: string[];
  categories: number[];
  countries: number[];
  createdTime: string;
  creator?: string;
  creatorId?: string;
  id: string;
  // 付款时效
  payAging: number;
  payTypes: number[];
  // 供应商类型
  supplierTypes: number[] | null;
  updateTime?: string;
  version: number;
}>;
export default () => {
  const formRef = ref(); // 表单校验对象使用

  const formData: FormData = reactive({
    arrivalPorts: [],
    categories: [],
    countries: [],
    createdTime: '',
    fundingId: '',
    id: '',
    payAging: null,
    payTypes: [],
    supplierTypes: null,
    updateTime: '',
    version: null,
  });

  const rules = reactive({
    supplierTypes: [{ required: true, message: '请选择供应商类型', trigger: 'change' }],
    categories: [{ required: true, message: '请选择大类', trigger: 'change' }],
    arrivalPorts: [{ required: true, message: '请选择目的港', trigger: 'change' }],
    countries: [{ required: true, message: '请选择不接单的国家', trigger: 'change' }],
    payTypes: [{ required: true, message: '请选择付款方式', trigger: 'change' }],
    payAging: [{ required: true, message: '请选择付款时效', trigger: 'change' }],
  });
  const setFormData = (paramsData: any) => {
    for (const key in formData) {
      if (Object.prototype.hasOwnProperty.call(paramsData, key)) {
        formData[key] = paramsData[key];
      }
    }
  };

  return { rules, formData, formRef, setFormData };
};
