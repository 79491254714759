
// 线索基础模块信息
import { defineComponent } from 'vue';
import useFilter from '@/hooks/useFilter';
import { showFiles } from '@/utils/publicMethods';
export default defineComponent({
  name: 'SupplierBaseInfo',
  props: ['data'],
  components: {},
  setup() {
    const { empty } = useFilter();
    const isFile = (data) => {
      return data && data.fundingBaseFileUrl !== null && data.fundingBaseFileUrl !== '';
    };
    return { empty, showFiles, isFile };
  },
});
