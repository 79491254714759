<template>
  <CrmFormLayout title="查看合同">
    <div class="detail-contract-detail-page">
      <div class="view__form">
        <div class="view__group">
          <label class="view__label">合同编号</label>
          <div class="view__input">{{ data.contractNo }}</div>
        </div>
        <div class="view__group">
          <label class="view__label">签约时间</label>
          <div class="view__input">{{ data.signDate }}</div>
        </div>
        <div class="view__group">
          <label class="view__label">生效时间</label>
          <div class="view__input">{{ data.startDate }}</div>
        </div>
        <div class="view__group">
          <label class="view__label">到期时间</label>
          <div class="view__input">{{ data.endDate }}</div>
        </div>
        <div class="view__group">
          <label class="view__label">预付比例(%)</label>
          <div class="view__input">{{ data.paymentRatio }}</div>
        </div>
        <div class="view__group">
          <label class="view__label">利率(年化%)</label>
          <div class="view__input">{{ data.interestRate }}</div>
        </div>
        <div class="view__group">
          <label class="view__label">最小使用天数</label>
          <div class="view__input">{{ data.minUseDays }}</div>
        </div>
        <div class="view__group">
          <label class="view__label">最大使用天数</label>
          <div class="view__input">{{ data.maxUseDays }}</div>
        </div>
        <div class="view__group">
          <label class="view__label">指定汇率</label>
          <div class="view__input">{{ data.fundingExchange }}</div>
        </div>
      </div>
      <div class="view-file-box">
        <div class="file-box-title">框架合同文件</div>
        <div class="file-component-wrap detail-view">
          <FileContent
            :readOnly="true"
            :fromType="3"
            :max="1"
            :fileList="contractUrlsArray"
          ></FileContent>
        </div>
      </div>
      <el-form>
        <div
          class="maili-edit__form label-con detail-contract"
          style="margin-top: 20px"
        >
          <el-form-item label="合同备注">
            <div class="view__area">{{ data.remark }}</div>
          </el-form-item>
        </div>
      </el-form>
      <div class="crm-button__group">
        <button class="crm-form__button primary" @click="close(false)">
          关闭
        </button>
      </div>
    </div>
  </CrmFormLayout>
</template>

<script>
import FileContent from '@/components/FileContent/FileContent.vue';
export default {
  components: { FileContent },
  props: ['detailData'],
  created() {
    this.getinitData(this.detailData.id);
  },
  data() {
    return {
      contractUrlsArray: [], // 框架合同文件
      data: {
        contractNo: null, // 合同编号
        signDate: null, // 签约时间
        startDate: null, // 生效时间
        endDate: null, // 到期时间
        paymentRatio: null, // 预付比例(%)
        interestRate: null, // 利率
        minUseDays: null, // 最小使用天数
        maxUseDays: null, // 最大使用天数
        remark: '', // 合同备注
      },
    };
  },
  methods: {
    getinitData(id) {
      this.ajax({
        type: 'GET',
        url: `/malicrm/funding/getFundingContractById/${id}`,
        data: {},
        success: (res) => {
          if (res.code === 200) {
            if (res.result) {
              const data = res.result;
              for (const key in data) {
                if (data[key] === '' || data[key] === null) {
                  data[key] = '-';
                }
              }
              this.data = data;
              this.initFile();
            }
          }
        },
      });
    },
    initFile() {
      if (this.data.licenseAttachment) {
        if (this.data.licenseAttachment.fundingBaseFileUrl) {
          this.contractUrlsArray = [
            {
              name: this.data.licenseAttachment.fundingBaseFileName,
              url: this.data.licenseAttachment.fundingBaseFileUrl,
            },
          ];
        }
      }
    },
    close() {
      this.$emit('close', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-contract-detail-page {
  .view__form {
    .view__input {
      width: 340px;
      margin-left: 6px;
    }
    .view__label {
      width: 90px;
      text-align: left;
    }
  }
  .view-file-box {
    width: 960px;
    overflow: hidden;
    background: #fff;
    padding: 0 0 6px 0;
    border: 1px solid #e3e4e6;
    margin-top: 20px;
    .file-box-title {
      font-size: 16px;
      color: #333;
      border-bottom: 1px dashed #e3e4e6;
      line-height: 66px;
      text-indent: 20px;
    }
    .file-wrap {
      margin-left: 21px;
      margin-top: 29px;
      width: 287px;
      height: 140px;
      box-sizing: border-box;
      overflow: hidden;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border: 1px solid rgba(191, 203, 217, 1);
      border-radius: 4px;
      padding-left: 18px;
      .file-l {
        flex-basis: 98px;
        height: 100px;
        img {
          width: 98px;
          height: 100px;
        }
      }
      .file-r {
        padding-left: 18px;
        box-sizing: border-box;
        width: 150px;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        color: #2878ff;
        font-size: 14px;
      }
    }
    .file-component-wrap {
      margin: -10px 20px 20px 20px;
      min-height: 60px;
      .new-list__upload {
        margin-top: 20px;
      }
    }
  }
}

.view__form {
  padding: 24px 20px 4px 20px;
  border: 1px solid #e3e4e6;
  background: #fff;
  margin-bottom: 20px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  .view__group {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .view__label {
    color: #1a1a1a;
    font-size: 14px;
    width: 60px;
    text-align: justify;
    line-height: 0;
    position: relative;
    top: 3px;

    &::after {
      content: '';
      display: inline-block;
      width: 100%;
    }
  }

  .view__input {
    margin-left: 19px;
    color: #1a1a1a;
    font-size: 14px;
    box-sizing: border-box;
    width: 360px;
    height: 40px;
    line-height: 40px;
    border: 1px solid #e3e4e6;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: keep-all;
  }
}

.view__area {
  border: 1px solid #e3e4e6;
  border-radius: 2px;
  padding: 12px;
  color: #1a1a1a;
  font-size: 14px;
  display: inline-block;
  width: 100%;
  min-height: 60px;
  line-height: 1.5;
  white-space: pre-wrap;
  word-break: break-all;
  overflow: hidden;
}
</style>
