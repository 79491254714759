import { reactive, ref, toRefs } from 'vue';

export default () => {
  const formRef = ref(); // 表单校验对象使用
  type FormData = {
    fundingBaseFileId: string | null | number; // 文件Id
    fundingBaseFileName: string | null; // 文件名称
    fundingBaseFileUrl: string | null; // 文件地址
    fundingId: string | null | number | undefined; // 资金方ID
  };
  const formData: FormData = reactive({
    fundingBaseFileId: null, // 文件Id
    fundingBaseFileName: null, // 文件名称
    fundingBaseFileUrl: null, // 文件地址
    fundingId: null, // 资金方ID
  });
  const customFormData = reactive({
    statementUrlsArray: [],
  });
  const rules = reactive({
    fundingBaseFileUrl: [
      {
        required: true,
        message: '请上传资金使用说明书文件',
        trigger: 'change',
      },
    ],
  });

  return { rules, formData, formRef, ...toRefs(customFormData) };
};
