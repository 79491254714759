import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24f28963"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mali-edit__form" }
const _hoisted_2 = { class: "crm-button__group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_CrmFormLayout = _resolveComponent("CrmFormLayout")!

  return (_openBlock(), _createBlock(_component_CrmFormLayout, {
    title: _ctx.setTitleLabel(_ctx.formType, ['新建', '编辑']) + '抬头',
    onClose: _ctx.close
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        model: _ctx.formData,
        "label-position": "top",
        ref: "formRef",
        rules: _ctx.formType != 3 ? _ctx.rules : {}
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_el_row, { gutter: 80 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "抬头",
                      prop: "consigneeName"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          modelValue: _ctx.formData.consigneeName,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.consigneeName) = $event)),
                          class: "mali-full__input tag-scroll no-delete",
                          multiple: "",
                          "multiple-limit": 5,
                          filterable: "",
                          remote: "",
                          "remote-method": _ctx.getconsigneeList,
                          onRemoveTag: _ctx.removeConsigneeName,
                          onChange: _ctx.changeConsigneeName,
                          onBlur: _ctx.changeConsigneeList,
                          onFocus: _ctx.changeConsigneeList,
                          placeholder: "请选择抬头"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.consigneeTypeList, (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item.id,
                                label: item.shortName,
                                value: item.shortName,
                                disabled: _ctx.formType === 2 && _ctx.disabledConsigneeName.includes(item.shortName)
                              }, null, 8, ["label", "value", "disabled"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue", "remote-method", "onRemoveTag", "onChange", "onBlur", "onFocus"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "资金额度(元)",
                      prop: "totalAmount"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.formData.totalAmount,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.totalAmount) = $event)),
                          clearable: "",
                          maxlength: 11,
                          onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.valInteger($event, 'totalAmount'))),
                          placeholder: "请输入资金额度(元)"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "口岸",
                      prop: "portName"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          class: "mali-full__input tag-scroll no-delete",
                          multiple: "",
                          "multiple-limit": 5,
                          modelValue: _ctx.formData.portName,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.portName) = $event)),
                          filterable: "",
                          remote: "",
                          clearable: "",
                          "remote-method": _ctx.getPortList,
                          onBlur: _ctx.changePortList,
                          onFocus: _ctx.changePortList,
                          placeholder: "请选择口岸"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.portNameList, (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item,
                                label: item,
                                value: item,
                                disabled: _ctx.formType === 2 && _ctx.disabledPortName && _ctx.disabledPortName.length && _ctx.disabledPortName.includes(item)
                              }, null, 8, ["label", "value", "disabled"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue", "remote-method", "onBlur", "onFocus"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "业务类型",
                      prop: "fundingBusinessType"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          class: "mali-full__input tag-scroll no-delete",
                          multiple: "",
                          modelValue: _ctx.formData.fundingBusinessType,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.fundingBusinessType) = $event)),
                          placeholder: "请选业务类型",
                          onRemoveTag: _ctx.removeFundingBusinessType
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Options.busTypeOpt, (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item.value,
                                label: item.label,
                                value: item.value,
                                disabled: 
                    _ctx.formType === 2 && _ctx.disabledFundingBusinessType && _ctx.disabledFundingBusinessType.length > 0 && _ctx.disabledFundingBusinessType.includes(item.value)
                  
                              }, null, 8, ["label", "value", "disabled"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue", "onRemoveTag"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["model", "rules"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          class: "crm-form__button primary",
          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.saveFormData && _ctx.saveFormData(...args)))
        }, "保存"),
        _createElementVNode("button", {
          class: "crm-form__button cancel",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.close(false)))
        }, "取消")
      ])
    ]),
    _: 1
  }, 8, ["title", "onClose"]))
}