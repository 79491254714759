
import { computed, defineComponent, ref } from 'vue';
import Pagination from '@/components/Pagination/Pagination.vue';
import usePagination from '@/hooks/usePagination';
import { erpConfirm, errorMessage, setTitleNum, successMessage, hasPermission } from '@/utils/publicMethods';
import useVisible from '@/hooks/useVisible';

import ViewForm from './form.vue';
import { httpPost } from '@/api';
export type DeclareResult = {
  arrivalPortDesc: string;
  arrivalPorts: string[];
  categories: string;
  categoryDesc: string;
  countries: string;
  countryDesc: string;
  createdTime: string;
  creator: string;
  creatorId: string;
  fundingId: string;
  id: string;
  lastOperator: string;
  lastOperatorId: string;
  orderBy: string;
  pageNum: number;
  pageSize: number;
  payAging: number;
  payAgingDesc: string;
  payTypeDesc: string;
  payTypes: string;
  supplierType: number;
  supplierTypeDesc: string;
  updateTime: string;
  version: number;
};

export default defineComponent({
  /** data 客户或 商机信息 */
  props: ['eventId', 'eventType', 'isHidden', 'data', 'fullName'],
  components: { Pagination, ViewForm },
  emits: ['getSize'],
  setup(props, ctx) {
    const queryData = {
      fundingId: props.eventId, // 资金方id
      pageSize: 5,
    };
    const { paginationRef, tableData, refashTable } = usePagination();
    const formType = ref(1); // 1新增2编辑 3删除
    const rowData = ref();
    const { visible } = useVisible();
    // 是否只能添加一条报单信息
    const hasAdd = computed(() => {
      return tableData.value && tableData.value < 1;
    });
    // 获取表格数据操作
    const getTableDatas = (data: Array<DeclareResult>, total: number) => {
      tableData.value = data;
      ctx.emit('getSize', setTitleNum('报单条件', total));
    };
    // 新增编辑操作
    const operateTask = (type, row?) => {
      formType.value = type;
      if (row) {
        rowData.value = row;
      }
      visible.value = true;
    };
    // 表单关闭操作，重新触发数据更新
    const closeVisible = () => {
      visible.value = false;
      refashTable();
    };
    const delTask = (row: DeclareResult) => {
      erpConfirm('确定删除该报单条件？').then(async () => {
        const url = '/malicrm/declareCondition/deleteDeclareCondition';
        const res = await httpPost(url, {
          id: row.id,
          version: row.version,
        });
        if (res.code === 200) {
          successMessage('已删除');
          refashTable();
        } else {
          errorMessage(res.message);
        }
      });
    };
    return {
      hasAdd,
      delTask,
      tableData,
      queryData,
      getTableDatas,
      operateTask,
      closeVisible,
      visible,
      formType,
      paginationRef,
      hasPermission,
      rowData,
    };
  },
});
