
import { httpGet, httpPost } from '@/api';
import { ajaxLoading, errorMessage, setTitleLabel, successMessage } from '@/utils/publicMethods';
import { defineComponent, ref, computed } from 'vue';
import useFormData from './useData';
export default defineComponent({
  components: {},
  props: {
    /** 新增编辑表单状态 1新增 2编辑 */
    formType: {
      type: [Number],
      default: 1,
    },
    data: {
      type: [Object],
    },
    eventId: {
      type: [String, Number],
    },
    fundingName: {
      type: [String, Number],
    },
  },
  emits: ['close'],
  setup(props, ctx) {
    const addressData = ref();
    let params: any = {
      fundingId: props.eventId,
      fundingShortName: props.fundingName,
    };
    console.log('props.params', params);
    if (props.formType === 2) {
      params = Object.assign({}, props.data);
      // params.isInPool = params.isInPool ? 1 : 0;
      // let data = props.data as any;
      // 设置区域
      // addressData.value = [data?.provinceId, data.cityId, data.areaId];
    }
    const {
      rules,
      formData,
      formRef,
      setFormData,
      disabledConsigneeName,
      consigneeTypeList,
      portNameList,
      disabledPortName,
      disabledFundingBusinessType,
      Options,
      valInteger,
    } = useFormData(params);
    /** 获取文件信息 */
    const readOnly = computed(() => {
      return props.formType > 2;
    });
    const getconsigneeList = async (name) => {
      const params = {
        consigneeType: 1,
        portName: name,
      };
      const res = await httpPost('/malicrm/consignee/queryByName', params);
      if (res.code === 200) {
        consigneeTypeList.value = res.result;
      } else {
        errorMessage(res.message);
      }
    };
    // 获取省市区联动
    const getArea = (data) => {
      formData.addressProvince = data.province.name;
      formData.provinceId = data.province.id;
      formData.addressCity = data.city.name;
      formData.cityId = data.city.id;
      formData.addressDistrict = data.county.name;
      formData.areaId = data.county.id;
    };
    const close = (status = false) => {
      ctx.emit('close', status);
    };
    const submitData = async () => {
      const params = {
        ...formData,
      };
      ajaxLoading.lock();
      const res = await httpPost('/malicrm/funding/saveConsignee', params);
      if (res.code === 200) {
        ajaxLoading.unLock();
        if (props.formType === 1) {
          successMessage('保存成功', 3000);
        }
        if (props.formType === 2) {
          successMessage('编辑成功', 3000);
        }
        close(true);
      } else {
        ajaxLoading.unLock();
        errorMessage(res.message);
      }
    };
    /** 提交表单 */
    const saveFormData = async () => {
      formRef.value.validate((valid) => {
        if (valid) {
          // formData.signDate = formatTime(formData.signDate, 'YYYY-MM-DD');
          submitData();
        } else {
          return false;
        }
      });
    };
    // 初始化数据
    const initData = async (id) => {
      if (!id) return;
      const res = await httpGet(`/malicrm/funding/getFundingConsigneeDetailById/${id}`);
      if (res.code === 200) {
        setFormData(res.result);
        formData.totalAmount = res.result.totalAmount;
        if (res.result.isUsedInBusiness) {
          disabledConsigneeName.value = res.result.consigneeName; // 保存已选中的抬头
          disabledPortName.value = res.result.portName;
          disabledFundingBusinessType.value = res.result.fundingBusinessType;
        }
      } else {
        errorMessage(res.message);
      }
    };
    const removeConsigneeName = (value) => {
      const index = (disabledConsigneeName.value as any).indexOf(value); // 获取到点击删除的位置
      if (index > -1) {
        formData.consigneeName.splice(index, 0, value);
      }
    };
    const changeConsigneeName = (value) => {
      // 获取抬头值改变
      if (props.formType === 2) {
        setTimeout(() => {
          if (value.length < disabledConsigneeName.value.length) {
            // 选中的长度小于禁用的长度时则说明删除的是最后一位
            formData.consigneeName.push(disabledConsigneeName.value[disabledConsigneeName.value.length - 1]);
          }
          formRef.value.validateField('consigneeName');
        }, 0);
      }
    };
    const changeConsigneeList = () => {
      if (consigneeTypeList.value && consigneeTypeList.value.length <= 0) {
        getconsigneeList('');
      }
    };
    const getPortList = async (name) => {
      const params = {
        portName: name,
      };
      const res = await httpPost('/malicrm/port/queryByName', params);
      if (res.code === 200) {
        portNameList.value = res.result;
      } else {
        errorMessage(res.message);
      }
    };
    const changePortList = () => {
      if (portNameList.value && portNameList.value.length <= 0) {
        getPortList('');
      }
    };
    const removePort = (value) => {
      console.log(value);
      // const index = (disabledPortName.value as any).indexOf(value); // 获取到点击删除的位置
      // if (index > -1) {
      //   formData.portName.splice(index, 0, value);
      // }
    };
    const changePort = (value) => {
      if (props.formType === 2) {
        setTimeout(() => {
          if (value.length < disabledPortName.value.length) {
            // 选中的长度小于禁用的长度时则说明删除的是最后一位
            formData.portName.push(disabledPortName[(disabledPortName as any).length - 1]);
          }
          formRef.value.validateField('portName');
        }, 0);
      }
    };
    const removeFundingBusinessType = (value) => {
      const index = (disabledFundingBusinessType as any).indexOf(value); // 获取到点击删除的位置
      if (index > -1) {
        formData.fundingBusinessType.splice(index, 0, value);
      }
    };
    getPortList('');
    getconsigneeList('');
    if (props.formType > 1) {
      initData((props.data as any).id);
    }
    return {
      close,
      formData,
      getArea,
      addressData,
      saveFormData,
      setFormData,
      rules,
      formRef,
      setTitleLabel,
      initData,
      readOnly,
      removeConsigneeName,
      changeConsigneeName,
      disabledConsigneeName,
      changeConsigneeList,
      getPortList,
      portNameList,
      consigneeTypeList,
      changePortList,
      changePort,
      getconsigneeList,
      removeFundingBusinessType,
      disabledFundingBusinessType,
      removePort,
      Options,
      valInteger,
    };
  },
});
