import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a2021fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mali-edit__form" }
const _hoisted_2 = { class: "crm-button__group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DictSelect = _resolveComponent("DictSelect")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_CrmFormLayout = _resolveComponent("CrmFormLayout")!

  return (_openBlock(), _createBlock(_component_CrmFormLayout, {
    title: _ctx.setTitleLabel(_ctx.formType) + '报单条件',
    onClose: _ctx.close
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        model: _ctx.formData,
        "label-position": "top",
        ref: "formRef",
        rules: _ctx.rules
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_el_row, { gutter: 80 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "供应商类型",
                      prop: "supplierTypes"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DictSelect, {
                          disabled: _ctx.formType > 2,
                          modelValue: _ctx.formData.supplierTypes,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.supplierTypes) = $event)),
                          name: "supplier_type",
                          multiple: true,
                          placeholder: "请选择供应商"
                        }, null, 8, ["disabled", "modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "大类",
                      prop: "categories"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DictSelect, {
                          disabled: _ctx.formType > 2,
                          modelValue: _ctx.formData.categories,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.categories) = $event)),
                          name: "goods_category_slim",
                          placeholder: "请选择大类",
                          multiple: true
                        }, null, 8, ["disabled", "modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "目的港",
                      prop: "arrivalPorts"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DictSelect, {
                          disabled: _ctx.formType > 2,
                          modelValue: _ctx.formData.arrivalPorts,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.arrivalPorts) = $event)),
                          customOption: _ctx.portList,
                          placeholder: "请选择目的港",
                          multiple: true,
                          filterable: true,
                          "collapse-tags": ""
                        }, null, 8, ["disabled", "modelValue", "customOption"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "不接单的国家",
                      prop: "countries"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DictSelect, {
                          disabled: _ctx.formType > 2,
                          modelValue: _ctx.formData.countries,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.countries) = $event)),
                          placeholder: "请选择不接单的国家",
                          multiple: true,
                          customOption: _ctx.countryList,
                          keyLabel: "countryName",
                          keyValue: "country",
                          filterable: true
                        }, null, 8, ["disabled", "modelValue", "customOption"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "付款方式",
                      prop: "payTypes"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DictSelect, {
                          disabled: _ctx.formType > 2,
                          modelValue: _ctx.formData.payTypes,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.payTypes) = $event)),
                          name: "pay_type",
                          multiple: true,
                          "collapse-tags": "",
                          placeholder: "请选择付款方式"
                        }, null, 8, ["disabled", "modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "付款时效",
                      prop: "payAging"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DictSelect, {
                          disabled: _ctx.formType > 2,
                          modelValue: _ctx.formData.payAging,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.payAging) = $event)),
                          name: "pay_aging",
                          placeholder: "请选择付款时效"
                        }, null, 8, ["disabled", "modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["model", "rules"]),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.formType < 3)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "crm-form__button primary",
              onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.saveFormData && _ctx.saveFormData(...args)))
            }, "保存"))
          : _createCommentVNode("", true),
        (_ctx.formType > 2)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "crm-form__button primary",
              onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
            }, "关闭"))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["title", "onClose"]))
}