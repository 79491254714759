export default function useVisible() {
  const empty = (data: any) => {
    if (data && data !== '') {
      return data;
    } else {
      return '-';
    }
  };

  return {
    empty,
  };
}
