
import { defineComponent, ref } from 'vue';
import Pagination from '@/components/Pagination/Pagination.vue';
import { erpConfirm, errorMessage, setTitleNum, successMessage, hasPermission, showFiles } from '@/utils/publicMethods';
import useVisible from '@/hooks/useVisible';
import usePagination from '@/hooks/usePagination';
import { httpPost } from '@/api';
export default defineComponent({
  props: ['eventId', 'eventType', 'scoreName', 'isHidden'],
  components: { Pagination },
  emits: ['getSize'],
  setup(props, ctx) {
    const queryData = {
      id: props.eventId,
      pageSize: 5,
    };
    const { paginationRef, tableData, refashTable } = usePagination();
    const formType = ref(1); // 1新增2编辑 3删除
    const rowData = ref();
    const { visible } = useVisible();
    // 获取表格数据操作
    const getTableDatas = (data: Array<any>, total: number) => {
      if (data) {
        data.forEach((item) => {
          for (const key in item) {
            if (item[key] === '' || item[key] === null) {
              item[key] = '-';
            }
          }
        });
        tableData.value = data;
      }
      tableData.value = data;
      ctx.emit('getSize', setTitleNum('银行信息', total));
    };
    const handleName = (row) => {
      const municipalityArr = ['北京市', '上海市', '天津市', '重庆市'];
      let name = row.addressCity;
      if (municipalityArr.includes(row.addressProvince)) {
        name = row.addressProvince;
      }
      return name;
    };
    // 新增编辑操作
    const operateTask = (type, row?) => {
      formType.value = type;
      if (row) {
        rowData.value = row;
      }
      visible.value = true;
    };
    // 表单关闭操作，重新触发数据更新
    const closeVisible = () => {
      visible.value = false;
      refashTable();
    };

    const delTask = (row) => {
      erpConfirm('确定删除该附件？').then(async () => {
        const url = '/malicrm/attachment/operateAttachment';
        const res = await httpPost(url, {
          ...row,
          eventId: props.eventId,
          source: props.eventType,
          operateType: 3,
        });
        if (res.code === 200) {
          successMessage('已删除');
          refashTable();
        } else {
          errorMessage(res.message);
        }
      });
    };
    return {
      delTask,
      tableData,
      queryData,
      getTableDatas,
      operateTask,
      closeVisible,
      visible,
      formType,
      paginationRef,
      rowData,
      hasPermission,
      showFiles,
      handleName,
    };
  },
});
